'use strict';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import _set from 'lodash.set';

const all_langs = require('@/assets/langs.json');
const localConfig = require('@/assets/local/localconfig.json');

export const langs = all_langs.filter((lang) => localConfig['availableLangs'].indexOf(lang.code) >= 0);

Vue.use(VueI18n);

// load default locale
export const DEFAULT_LOCALE = 'en';

function selectLanguage() {
    const urlCode = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');
    if (langs.find((l) => l.code === urlCode)) return { initLocale: urlCode, isDefault: false };

    const navigatorLanguages = window.navigator.languages;
    for (let k = 0; k < navigatorLanguages.length; ++k) {
        if (langs.find((l) => l.code == navigatorLanguages[k]))
            if (langs.find((l) => l.code == navigatorLanguages[k])) return { initLocale: navigatorLanguages[k], isDefault: false };
    }
    if (!langs.map((lang) => lang.code).includes(DEFAULT_LOCALE)) return { initLocale: langs[0].code, isDefault: !window.__prerender };

    return { initLocale: DEFAULT_LOCALE, isDefault: !window.__prerender };
}

const overrideWithLocalConfig = (locale, messages) => {
    if (localConfig.i18n === undefined)
        return messages;

    // Erase the current translations as soon as a key is overriden
    // in default locale
    // Might be optimized
    if (locale !== DEFAULT_LOCALE) {
        for (const k of Object.keys(localConfig.i18n[DEFAULT_LOCALE])) {
            _set(messages, k, undefined);
        }
    }

    if (localConfig.i18n[locale] === undefined)
        return;

    for (const k of Object.keys(localConfig.i18n[locale])) {
        _set(messages, k, localConfig.i18n[locale][k]);
    }
    return messages;
}

const getLocalizedConfig = (locale) => {
    const localizedKeys = ['about', 'description', 'name'];
    const localizedConfig = {};
    for (const key of localizedKeys) {
        const value = localConfig[key];

        // value might not have been localized
        if (typeof value === 'string') {
            localizedConfig[key] = value;
            continue;
        }

        if (value[locale] !== undefined) {
            localizedConfig[key] = value[locale];
        } else if (value[DEFAULT_LOCALE] !== undefined) {
            localizedConfig[key] = value[DEFAULT_LOCALE];
        } else {
            localizedConfig[key] = value[Object.keys(value)[0]];
        }
    }
    return localizedConfig;
};

export const { initLocale, isDefault } = selectLanguage();

const loadedLanguages = [initLocale];
const localMessages = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${initLocale}.json`);
localMessages['local'] = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/local/i18n/${initLocale}.json`);
localMessages['LOCAL_CONFIG'] = getLocalizedConfig(initLocale);

overrideWithLocalConfig(initLocale, localMessages);

const messages = { [initLocale]: localMessages };

if (initLocale != DEFAULT_LOCALE) {
    loadedLanguages.push(DEFAULT_LOCALE);
    const defaultMessages = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${DEFAULT_LOCALE}.json`);
    defaultMessages['local'] = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/local/i18n/${DEFAULT_LOCALE}.json`);
    defaultMessages['LOCAL_CONFIG'] = getLocalizedConfig(DEFAULT_LOCALE);
    overrideWithLocalConfig(DEFAULT_LOCALE, defaultMessages);

    messages[[DEFAULT_LOCALE]] = defaultMessages;
}


export const i18n = new VueI18n({
    locale: initLocale,
    fallbackLocale: DEFAULT_LOCALE,
    silentFallbackWarn: true,
    messages: messages,
});

export const loadLanguage = async (lang) => {

    // requested lang is already the current locale
    if (i18n.locale === lang) {
        return;
    }

    // requested lang is not available
    const isLangAvailable = langs.find((l) => l.code === lang);
    if (!isLangAvailable) {
        return;
    }

    // load locale if needed
    if (!loadedLanguages.includes(lang)) {
        const messages = await import(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${lang}.json`);
        messages['local'] = await import(/* webpackChunkName: "i18n-[request]" */ `@/assets/local/i18n/${lang}.json`);
        overrideWithLocalConfig(lang, messages);
        i18n.setLocaleMessage(lang, messages);
        i18n.mergeLocaleMessage(lang, { LOCAL_CONFIG: getLocalizedConfig(lang) });
        loadedLanguages.push(lang);
    }

    // set locale globally
    i18n.locale = lang;
};

// load user's preferred language
// TODO voir ce qu'on en fait
// loadLanguage(window.navigator.language.split('-')[0]);
