const localConfig = require('@/assets/local/localconfig.json');
import router from '@/aktivisda/router';
import Vue from 'vue';
import VueMatomo from 'vue-matomo';

if (localConfig.matomo && localConfig.matomo.enabled) {
    Vue.use(VueMatomo, {
        host: localConfig.matomo.host,
        siteId: localConfig.matomo.siteId,
        trackerFileName: 'matomo',
        router: router,
        enableLinkTracking: true,
        requireConsent: false, // todo true
        trackInitialView: true,
        disableCookies: true,
        enableHeartBeatTimer: false,
        heartBeatTimerInterval: 15,
        debug: false,
        userId: undefined,
        cookieDomain: undefined,
        domains: undefined,
        preInitActions: [],
    });
}
